<template>
  <div class="bg-white border-r-10 p-4">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-block w-100">
        <h1 class="fs-4 w-100 text-primary-0 fw-bold">Personal details</h1>
        <p><span class="red">*</span> These fields are required</p>
      </div>
    </div>

    <form class="mt-4">
      <div class="profile-grid gap-4 pb-3">
        <div class="field-blue col-2">
          <div>
            <label for="">Name(s)</label>
            <label for="" class="d-block d-md-none">Last name(s)</label>

          </div>
          <skeleton v-if="dashboard.isLoading || isLoadingForm" borderRadius="10px" height="100%"
            class="skeleton-dark" />
          <div class="d-flex flex-column flex-md-row gap-2" v-else>
            <input-text class="col-12 col-md-6" v-model="formFields.name" type="text" name="name"
              :disabled="formStatus === 'update'" />
            <input-text v-model="formFields.last_name" type="text" name="last_name"
              :disabled="formStatus === 'update'" />
          </div>
        </div>

        <div class="field-blue col-2">
          <label for="">Time Zone <span class="red">*</span></label>
          <skeleton v-if="dashboard.isLoading || isLoadingForm" borderRadius="10px" height="100%"
            class="skeleton-dark" />
          <dropdown v-else v-model="formFields.timezone_change" :options="listings.timesZones.data"
            :loading="listings.timesZones.isLoading" optionLabel="timezone_change" optionValue="timezone_change"
            placeholder="Select a Time Zone" name="timezone" :filter="true" :disabled="formStatus === 'update'" />
        </div>

        <div class="field-blue col-2">
          <label for="">Email</label>
          <skeleton v-if="dashboard.isLoading || isLoadingForm" borderRadius="10px" height="100%"
            class="skeleton-dark" />
          <input-text v-else v-model="formFields.email" type="email" name="email" :disabled="formStatus === 'update'" />
        </div>

        <div class="field-blue col-2 ">
          <label for="">Phone <span class="red">*</span></label>
          <!-- loading -->
          <skeleton v-if="dashboard.isLoading || isLoadingForm" borderRadius="10px" height="100%"
            class="skeleton-dark" />
          <div class="d-flex flex-column flex-md-row gap-2" v-else>
            <dropdown class="col-12 col-md-6" v-model="formFields.phone_code_id" :options="listings.countrycodes.data"
              :loading="listings.countrycodes.isLoading" optionLabel="dial_code" optionValue="id"
              placeholder="Select code" style="min-width: 150px" :filter="true" name="value"
              :disabled="formStatus === 'update'" @filter="selectCode" />
            <input-text name="cellphone" type="number" class="" v-model="formFields.cellphone" :useGrouping="false"
              :disabled="formStatus === 'update'" />
          </div>
        </div>

        <div class="field-blue col-2" v-if="completed_info">
          <label for="">Password</label>
          <skeleton v-if="dashboard.isLoading || isLoadingForm" borderRadius="10px" height="100%"
            class="skeleton-dark" />
          <div v-else class="d-flex flex-column flex-md-row gap-2 align-items-center">
            <password strongRegex="^(?=.{10,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$"
              mediumRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})" toggleMask v-model="password"
              :disabled="formStatus === 'update'" name="password" placeholder="Input your new password">
              <template #footer>
                <p class="mt-2">Restricts</p>
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                  <li>At least one lowercase</li>
                  <li>At least one uppercase</li>
                  <li>At least one numeric</li>
                  <li>Minimum 10 characters</li>
                </ul>
              </template>
            </password>
            <button style="" type="button" class="
           btn text-secondary-1 border-secondary-1 w-auto bg-hover-light-secondary-1 p-2 px-4
          " v-if="formStatus === 'edit'" @click="() => update('password')" :disabled="isLoadingForm">
              Change
            </button>
          </div>

        </div>
        <div class="field-blue col-2">
          <label>Birthday <span class="red">*</span></label>
          <skeleton v-if="dashboard.isLoading || isLoadingForm" borderRadius="10px" height="1.5rem"
            class="skeleton-dark" />
          <Calendar v-else id="icon" v-model="formFields.birth" :showIcon="true" name="birth" :maxDate="new Date(store.getters['shared/currentTimeTimezone'])"
            :disabled="formStatus === 'update'" />
        </div>
        <div class="field-blue col-2">
          <label>Gender <span class="red">*</span></label>
          <skeleton v-if="dashboard.isLoading || isLoadingForm" borderRadius="10px" height="1.5rem"
            class="skeleton-dark" />
          <dropdown v-else v-model="formFields.gender" :options="listings.genders" optionLabel="value"
            optionValue="value" placeholder="Select gender" name="value" :disabled="formStatus === 'update'" />
        </div>
      </div>

      <hr />

      <div class="
          profile-grid profile-grid-2
          align-items-center
          field-blue
          gap-4
          pt-3
        ">
        <label for="">Your Spanish knowledge <span class="red">*</span></label>
        <skeleton v-if="dashboard.isLoading || isLoadingForm" borderRadius="10px" height="100%" class="skeleton-dark" />
        <dropdown v-else v-model="formFields.spanish_knowledge_id" :options="listings.spanishKnowledge.data"
          :loading="listings.spanishKnowledge.isLoading" optionLabel="name" optionValue="id"
          placeholder="Select your Spanish knowledge" name="spanishKnowledge" :disabled="formStatus === 'update'" />
        <label for="">Learning path  <span class="red">*</span></label>
        <skeleton v-if="dashboard.isLoading || isLoadingForm" borderRadius="10px" height="100%" class="skeleton-dark" />
        <dropdown v-else v-model="formFields.learning_path_id" :options="listings.learningPath.data"
          :loading="listings.learningPath.isLoading" optionLabel="name" optionValue="id"
          placeholder="Select learning path" name="learning_path_id" :disabled="formStatus === 'update'" />
      </div>

      <div class="pt-4 field-blue">
        <label for="">About yourself</label>
        <div class="profile-description">
          <skeleton v-if="dashboard.isLoading || isLoadingForm" borderRadius="10px" height="5rem" class="skeleton-dark" />
          <Textarea v-else v-model="formFields.my_goal_about" class="w-100 mt-2" autoResize="true" height="100%" rows="5"
            name="my_goal_about" data-content="" :disabled="formStatus === 'update'" />
          <p class="text-area-placeholder" v-if="!formFields.my_goal_about && !isLoadingForm && !dashboard.isLoading">
            Tell us about yourself.<br/>Why do you want to learn Spanish?<br/>What do you do for a living?<br/>What are some of your interests; sports, books, music, movies and hobbies?<br/>Anything else you would like our teachers to know just write it here.
          </p>
        </div>
      </div>
    </form>
    <div class="mt-3 d-flex gap-3 align-items-center justify-content-end w-100">
      <button class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            p-2
            px-4
          " v-if="formStatus === 'edit'" :disabled="isLoadingForm" @click="changeFormStatus({ mood: 'update' })">
        Cancel
      </button>
      <button class="
            btn
            bl-shadow
            text-white
            bg-secondary-1 bg-hover-secondary-1
            p-2
            px-4
          " v-if="formStatus === 'edit'" @click="() => update()" :disabled="isLoadingForm">
        Save
      </button>
      <button class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            p-2
            px-4
          " v-if="formStatus === 'update'" @click="changeFormStatus({ mood: 'edit' })">
        <i class="pi pi-pencil me-2"></i>
        Edit profile
      </button>
    </div>
  </div>
</template>
<script>
import usePersonalDetails from "@/modules/students/composables/StudentProfile/usePersonalDetails";
import useDashboard from "@/modules/students/composables/Dashboard/useDashboard";
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "PersonalDetailsComponent",
  setup() {
    const {
      formFields,
      changeFormStatus,
      update,
      listings,
      formStatus,
      loadServices,
      isCompletedInfo,
      isLoadingForm,
      selectCode,
      password,
    } = usePersonalDetails();

    const store = useStore();
    const { completed_info } = store.getters["shared/getUserAuthentication"];

    const { dashboard } = useDashboard();

    loadServices();

    return {
      formFields,
      password,
      isLoadingForm,
      selectCode,
      isCompletedInfo,
      changeFormStatus,
      update,
      listings,
      dashboard,
      formStatus,
      completed_info,
      store
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &-1 {
    grid-template-columns: 1fr;
  }

  &-2 {
    grid-template-columns: 12rem 1fr;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.col-2 {
  display: grid;
  grid-template-columns: 6rem 1fr;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 576px) {
    grid-template-columns: 4.5rem 1fr;
    align-items: flex-start
  }
}

button {
  max-width: 180px;
  width: 100%;
}

.container-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}
</style>
