<template>
  <div class="student-profile-grid">
    <banner-component />
    <personal-details-component />
    <!-- <google-calendar-component /> -->
    <statistics-component />
    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog> 

  </div>
</template>

<script>
import BannerComponent from "@/modules/students/components/StudentProfile/BannerComponent";
import PersonalDetailsComponent from "@/modules/students/components/StudentProfile/PersonalDetailsComponent";
import GoogleCalendarComponent from "@/modules/students/components/StudentProfile/GoogleCalendarComponent";
import StatisticsComponent from "@/modules/students/components/StudentProfile/StatisticsComponent";

export default {
  name: "StudentProfileView",
  components: {
    BannerComponent,
    PersonalDetailsComponent,
    GoogleCalendarComponent,
    StatisticsComponent,
  },

};
</script>

<style lang="scss" scoped>
.student-profile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  & div:nth-child(1),
  & div:nth-child(2) {
    grid-column: 1 / 3;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    &> * + * {
      margin-left: 2rem;
    }
  }
}
</style>