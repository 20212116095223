import { computed, ref, watchEffect , onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import selectedImage from "@/shared/helpers/selectedImageHelper";
import  useMenuBar from '@/shared/composables/useMenuBar'

import moment from "moment";
const usePersonalDetails = () => {
  const store = useStore();
  const inputFile = ref();
  const router = useRouter();
  const isLoadingForm = ref(false);
  const isLoadingPhoto = ref(false);
  const isCompletedInfo = ref(false)
  const setInfo = ref(false)
  const password = ref('')
  const formFields = ref({
    name: "",
    email: "",
    email_google_calendar: "",
    birth: "",
    cellphone: "",
    last_name:"",
    gender: "",
    my_goal_option: "",
    my_goal_about: "",
    phone_code_id: "",
    spanish_knowledge_id: "",
    learning_path_id: "",
    timezone: "",
    timezone_change: "",
  });
  const { toggleMenuHamburger  } = useMenuBar()
 
  onBeforeMount(() => {
    const studentInfo = store.getters["students/studentInformation"]
    const userAuthenticated = store.getters["shared/getUserAuthentication"]
    isCompletedInfo.value = userAuthenticated.completed_info

    if(!studentInfo?.is_new_student && !isCompletedInfo.value){
      store.commit("students/profilePersonalDetailsStatus", {mood:"edit"});
    }
  })

  const studentInfo = computed(
    () => store.getters["students/studentInformation"]
  );

  const loadServices = async () => {
    await store.dispatch("students/dashboardStart");
  };

  const urlAvatar = ref(
    ""
  );

  const selectCode = (e) => {
    const code = (e.value.includes("+") ? "" : "+") + e.value;
    console.log(code)
    const { countrycodes } = store.getters["shared/getListings"];
    const country = countrycodes.data.find((c) => c.dial_code === code);
    if(country) formFields.value.phone_code_id = country.id ;
  } 

  const listings = computed(() => store.getters["shared/getListings"]);
  /* UPDATE STUDENT INFO */
  watchEffect(() => {
    const info = studentInfo.value;
    const hasValues = Object.values(info)?.filter((v) => v).length > 1;
    
    if (hasValues && !setInfo.value) {
      urlAvatar.value = info.photo;
      setInfo.value = true
      for (let [key, value] of Object.entries(info)) {
        if(key === "birth"){
          formFields.value[key] = value ? moment(value).format("MM/DD/YYYY") : ''
        }else
          formFields.value[key] = value;
      }
      delete formFields.value.password
    }else{
      formFields.value.email_google_calendar = info.email_google_calendar;
    }
  });

  const changeAvatar = () => {
    inputFile.value.click();
  };

  const onSelectedImage = async ({ target }) => {
    const { success } = selectedImage({ target, image: urlAvatar });
    if (success) {
      isLoadingPhoto.value = true;
      await store.dispatch("students/editPhotoStudent", {
        payload: target.files[0],
      });
      isLoadingPhoto.value = false;
      await store.dispatch("students/getStudentInfo");
    }
  };

  const notification = { isOpen: true };

  const formStatus = computed(
    () => store.getters["students/profilePersonalDetails"].status.mood
  );

  const changeFormStatus = (payload) => {
    store.commit("students/profilePersonalDetailsStatus", payload);
  };

  const validProfile = () => {
    const  password_  = password.value;
    if(password_.length > 0){
    const regex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})/
    );
    return regex.test(password_);
    }else{
      return true
    }
  };

  const confirmMiniTour =(isOpen) => {
    toggleMenuHamburger()
    store.commit('students/confirmTour', { isOpen })
  }

  const confirmTour = computed(
    () => store.getters["students/confirmTour"]
  );

  const update = async (type="default") => {
    isLoadingForm.value = true;
    if(formFields.value.timezone_change){
      const timezoneChange = listings.value.timesZones.data.find(item => item.timezone_change === formFields.value.timezone_change)
  
      formFields.value.timezone_change = timezoneChange.timezone_change
      formFields.value.timezone = timezoneChange.timezone
    }

    if (validProfile()) {
      let { res } = await store.dispatch(
        "students/profilePersonalDetailsStatus",
        {
          payload: {
            ...formFields.value,
            password: type !== 'default' ? password.value : '',
            birth: moment
              .parseZone(formFields.value.birth)
              .utc()
              .format()
              .substr(0, 10),
          },
        }
      );
      store.dispatch("shared/resetNotificationStatus");
      if (res) {
        store.commit("students/profilePersonalDetailsStatus", {
          mood: "update",
        });
        await store.dispatch("students/getStudentInfo");

        if(!isCompletedInfo.value){
          confirmMiniTour(true)
          isCompletedInfo.value = true
        }
      }
      password.value = ''
    } else {
      notification.message = "The password format is incorret.";
      notification.status = "error";
      store.commit("shared/uiNotificationStatus", notification);
    }
    isLoadingForm.value = false;
  };

  const stadisticLessons = computed(() => {
    return store.getters["students/stadisticLessons"];
  })

  return {
    isCompletedInfo,
    stadisticLessons,
    studentInfo,
    listings,
    urlAvatar,
    changeAvatar,
    inputFile,
    selectCode,
    onSelectedImage,
    formStatus,
    changeFormStatus,
    formFields,
    isLoadingForm,
    update,
    loadServices,
    isLoadingPhoto,
    confirmTour,
    password,
    confirmMiniTour
  };
};

export default usePersonalDetails;
