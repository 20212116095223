<template>
  <skeleton v-if="dashboard.isLoading" borderRadius="10px" height="100%" width="100%" class="mb-3 skeleton-dark" />
  <div class="bg-primary-5 border-r-10 py-4 px-5 grid-banner"
    :class="'grid-banner-' + studentInfo.level_name?.toLowerCase().substr(6, 2)" v-else>
    <div class="text-center">
      <h1 class="text-primary-0 m-0 fs-3 fw-bold">Your Level</h1>
      <h2 class="m-0 fw-bold " :class="'title-' + studentInfo.level_name?.toLowerCase().substr(6, 2)">
        {{ studentInfo.level_name?.substr(6, 2) }}
      </h2>
    </div>
    <div>
      <div class="header-user- header-user-big mx-auto w-100 text-center">
        <div class="position-relative d-flex user-picture pointer" @click="changeAvatar">
          <skeleton v-if="isLoadingPhoto" borderRadius="50%" height="8rem" width="8rem" class="skeleton-dark m-auto" />
          <avatar :image="urlAvatar" shape="circle" class="" v-else />
          <div class="
              edit-avatar
              text-secondary-2
              align-items-center
              justify-content-center
            ">
            <i class="pi pi-camera"></i>
            <p>Edit photo</p>
            <input type="file" class="d-none" ref="inputFile" @change="onSelectedImage" />
          </div>
        </div>
        <h1 class="fs-4 text-primary-1 text-center mt-2">
          {{ studentInfo.name }} {{ studentInfo.last_name }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import usePersonalDetails from "@/modules/students/composables/StudentProfile/usePersonalDetails";
import useDashboard from "@/modules/students/composables/Dashboard/useDashboard";

export default {
  name: "BannerComponent",
  setup() {
    const {
      inputFile,
      changeAvatar,
      urlAvatar,
      onSelectedImage,
      studentInfo,
      isLoadingPhoto,
    } = usePersonalDetails();
    const { dashboard } = useDashboard();

    return {
      inputFile,
      isLoadingPhoto,
      changeAvatar,
      studentInfo,
      dashboard,
      urlAvatar,
      onSelectedImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.grid-banner {
  align-items: center;
  display: grid;
  gap: 2.5rem;
  grid-template-columns: 12rem 1fr 12rem;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    direction: rtl;
  }
}

h2 {
  font-size: 4.5rem;
}
</style>
