<template>
  <div class="bg-white border-r-10 p-4">
    <skeleton
      v-if="isLoadingForm"
      borderRadius="10px"
      height="10rem"
      class="mb-3 skeleton-dark"
    />
    <template v-else>
      <h2 class="fs-5 text-primary-0 fw-bold">Your class statistics</h2>
      <div class="d-flex flex-column flex-md-row gap-3 mt-3 justify-content-between">
        <div class="bg-accent-3 px-3 py-2 rounded-3 border-accent-1 w-100">
          <p class="text-secondary-1 fs-3 fw-bold">
            {{stadisticLessons.total}}
          </p>
          <p class="fs-6">Total classes so far.</p>
        </div>
        <div class="bg-accent-3 p-3 rounded-3 border-accent-1 w-100">
          <p class="text-secondary-1 fs-3 fw-bold">
            {{stadisticLessons.month}}
          </p>
          <p>This month.</p>
        </div>
        <div class="bg-accent-3 p-3 rounded-3 border-accent-1 w-100">
          <p class="text-secondary-1 fs-3 fw-bold">
            {{stadisticLessons.week}}
          </p>
          <p>This week.</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import usePersonalDetails from "@/modules/students/composables/StudentProfile/usePersonalDetails";
export default {
  name: "StatisticsComponent",
  setup() {
    const { stadisticLessons , isLoadingForm } = usePersonalDetails();
    return {
      stadisticLessons,
      isLoadingForm
    };
  },
};
</script>

<style lang="scss" scoped></style>
