<template>
  <div
    class="bg-white border-r-10 p-4 d-flex justify-content-center flex-column"
  >
    <skeleton
      v-if="dashboard.isLoading"
      borderRadius="10px"
      height="10rem"
      class="mb-3 skeleton-dark"
    />
    <template v-else>
      <h2 class="fs-5 text-primary-0 fw-bold">Your Google Calendar account</h2>
      <skeleton
        v-if="isLoadingAccount"
        borderRadius="10px"
        height="5rem"
        class="mb-3 skeleton-dark"
      />
      <div class="mt-2 d-flex flex-column flex-md-row gap-4 align-items-start align-items-md-center justify-content-center" v-else>
        <template v-if="formFields.email_google_calendar !== '' && formFields.email_google_calendar !== null">
          <p>{{ formFields.email_google_calendar }}</p>
          <button
            @click="handleSignoutClick"
            class="btn text-white bg-secondary-1 bg-hover-secondary-1 py-2 px-4 bl-shadow"
          >
            Unlink account
          </button>
        </template>
        <template v-else>
          <p>You haven't linked any account yet.</p>
          <button
            @click="handleAuthClick"
            class="btn"
          >
          <img src="/assets/images/btn_google_signin_light_normal_web.png" alt="">
          </button>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
// import useGoogleCalendar from "@/modules/students/composables/StudentProfile/useGoogleCalendar";
import usePersonalDetails from "@/modules/students/composables/StudentProfile/usePersonalDetails";
import useDashboard from "@/modules/students/composables/Dashboard/useDashboard";

import useCalendar from "@/shared/composables/Calendar/useCalendar";
import { onMounted } from 'vue' 

export default {
  name: "GoogleCalendarComponent",
  setup() {

    const { dashboard } = useDashboard();
    return {
      ...usePersonalDetails(),
      ...useCalendar(),
      dashboard,
    };
  },
};
</script>
